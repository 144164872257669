import React from "react"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import { BlogMeta } from "../../components/blogMeta"

import "../../styles/blog.css"

const GroceezyLink = () => (
  <a href="https://groceezy.com" target="_blank">
    Groceezy
  </a>
)
const Cloud66Link = () => (
  <a href="https://cloud66.com" target="_blank">
    Cloud 66
  </a>
)

export default function RailsStrongParametersShortcutForScopingCurrentUserToRecord() {
  return (
    <Layout>
      <SEO title="Cloud 66 First Impressions" />
      <main>
        <h1>Cloud 66 First Impressions</h1>
        <BlogMeta
          author="Primespot Engineering"
          published="June 15, 2020"
          tags={["Engineering", "Deployment"]}
        />
        <p>Have you ever deployed software to production?</p>
        <p>
          If so, you know that it’s hard. A lot can go wrong. Sometimes, the
          software doesn’t behave at all in production like it does in
          development.
        </p>
        <p>
          I have had my fair share of deployment difficulties. I remember the
          first time I tried to deploy a small Meteor.js app. That was NOT a
          smooth experience. My experience deploying Ruby on Rails isn’t as bad.
          But it is more difficult, in my experience, than Node.
        </p>
        <p>
          Now, if you have looked over the Primespot Blog, you will see that
          there is a comprehensive web server setup guide. Following this guide
          will get you most of the way to a production deployment on a VPS. But
          it will still take you a couple of hours to push through it.
        </p>
        <p>
          Many solutions have been created to address these problems. The most
          well-known is Heroku. This article summarizes my first impressions
          dealing with a Heroku competitor--
          <Cloud66Link />.
        </p>
        <p>
          First, let me take a step back and address a few things. There are a
          few nearly automated deployment solutions for Rails applications on
          the market. A few of them are Heroku, Engine Yard, Hatchbox, and Cloud
          66. The differences between them seem to be maturity, feature set, and
          pricing. Consider this table below comparing them to each other.
        </p>
        <ServiceCompareTable />
        <p>
          While this list is somewhat opinionated, I think it’s reasonably fair.
        </p>
        <p>
          Looking at the table, it seems that <Cloud66Link /> is without a doubt
          the best bang for the buck (second place might be Hatchbox). My
          experience has been limited to Heroku among these options, though. So
          I decided to give <Cloud66Link /> a try for Primespot’s newly released{" "}
          <GroceezyLink /> app.
        </p>
        <h2>First steps</h2>
        <p>
          <Cloud66Link /> has a very user-friendly and excellent website. It was
          easy to browse around and understand the highlights of the platform. I
          was left with a few questions that were difficult to answer. In fact,
          I had to scour the docs before I fully committed to giving them a
          shot.
        </p>
        <p>
          Sign up was easy and didn’t require any payment information. They gave
          me a 14 day free trial which was nice. From there, the platform
          started to walk me through the process of deploying <GroceezyLink />.
        </p>
        <h2>Setting up the application</h2>
        <p>
          First, I had to link my Git repository to <Cloud66Link /> using an API
          key. It was more or less point and click to do this. From there,{" "}
          <Cloud66Link />
          was able to begin scanning the codebase to see what technology was
          used to build it.
        </p>
        <p>
          This process took a couple of minutes. But when it was done, I was
          presented a list of all the services that would need to be installed
          on a web server to make the application work. This list looked correct
          to me, so I pushed on.
        </p>
        <h2>Preparing for deployment</h2>
        <p>
          The next step was to choose where I wanted to deploy the application.
          <Cloud66Link /> offers a number of options. I chose Digital Ocean
          since that is my preferred platform.
        </p>
        <p>
          Once again, I needed to link an account’s API key with <Cloud66Link />
          . First it was Github, now it was Digital Ocean.
        </p>
        <p>
          This process was also mostly point and click. <Cloud66Link /> linked
          me to the exact pages I needed to visit to accomplish this.
        </p>
        <p>
          From there, I selected the size of the Droplet (Digital Ocean
          terminology for VPS) and began the deployment.
        </p>
        <h2>The Deployment Process</h2>
        <p>
          This process took about 35 minutes. The software started from a
          bare-metal server with only Ubuntu Linux installed. From there, it
          configured the entire web server. Here is a non-comprehensive list of
          some of what was installed and set up.
        </p>
        <ul>
          <li>SSH</li>
          <li>Firewall</li>
          <li>Nginx</li>
          <li>Nodejs</li>
          <li>Ruby</li>
          <li>A deployment account</li>
          <li>The application</li>
        </ul>
        <p>
          Basically, everything necessary to get the software up and running
          securely was installed and configured for me. I was able to install an
          SSL certificate with the click of a button as well.
        </p>
        <h2>The Drum Roll</h2>
        <p>
          Now that <Cloud66Link /> emailed me to tell me that deployment was
          successful, I was able to go check it out. I was provisioned a
          subdomain through <Cloud66Link /> that I could use to test it before
          pointing my official domain to the newly-created web server.
        </p>
        <p>And...</p>
        <p>It all worked. It just worked.</p>
        <p>
          I fully expected to have to spend some time tinkering with my code
          base to get it ready for production. After all, Rails isn’t the
          easiest framework in the world to deploy.
        </p>
        <p>
          I was actually kind of shocked at how easy it was. But it was a very
          pleasant surprise.
        </p>
        <h2>A Few More Notes</h2>
        <p>
          The process was about as painless as I could imagine. The pricing at
          $15 bucks a month for a server is generous. I’d have to rate the
          service an A+ at this point.
        </p>
        <p>
          But I stumbled upon another handy addition as well. The{" "}
          <Cloud66Link />
          Toolbelt. This is a small software download that makes it easier to
          interact with my web servers deployed with <Cloud66Link /> from the
          command line. Using this tool, I was able to easily SSH into my web
          server to poke around and see how <Cloud66Link /> configured
          everything.
        </p>
        <p>
          This tool can also be used to view log files on the server with a
          single command. Sweet!
        </p>
        <h2>The Verdict</h2>
        <p>
          So far, I’m actually quite amazed at how good <Cloud66Link /> is. I
          really didn’t expect it to be this painless. I’ll have to spend more
          time with the service to truly understand its value, but as of now, I
          plan to use it more.
        </p>
      </main>
    </Layout>
  )
}

function ServiceCompareTable() {
  return (
    <div className="flex flex-col mb-4">
      <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Service
                </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Price
                </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Maturity / Age
                </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Feature Set
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              <tr>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                  Heroku
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                  Medium - High
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                  Mature
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                  High
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                  Engine Yard
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                  High
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                  Mature
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                  High
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                  Hatchbox
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                  Low - Medium
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                  Young
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                  Low - Medium
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                  Cloud 66
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  Low
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  Mature
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  Medium - High
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
